<template>
  <div class="flex flex-col flex-1">
    <div
      class="bg-[#EDF4FF] rounded-md relative px-[24px] pt-[28px] flex justify-between mb-5">
      <div class="flex flex-col gap-2">
        <div class="flex items-center gap-1">
          <span class="font-simplerBold text-[24px] text-secondary-900">
            {{ courseStore.course.caption }}
          </span>
          <span class="font-simplerRegular text-base text-secondary-800">
            /
          </span>
          <span class="font-simplerRegular text-sm text-secondary-800">
            {{ group?.owner_id.fullName }}
          </span>
          <ProgressInfo
            :progress="
              Math.round(currentEnrollmentFormat.enrollment_total_progress)
            "
            :items-progress="lessonsProgress"/>
        </div>
        <div
          :dir="courseStore.course?.locale === 'en' ? 'ltr' : 'rtl'"
          class="course-description hidden"
          v-html="courseStore.course?.description"></div>
        <div class="flex items-center gap-2 mt-2">
          <PVButton
            class="flex-row-reverse h-[33px]"
            :label="enrollmentState.action"
            @click="router.push(enrollmentState.route)"/>
          <div class="flex items-center gap-1 bg-white rounded-4 px-3 py-1">
            <span class="font-semibold text-sm">
              {{ accountStore.user.coins }} אמיתקוין
            </span>
            <img :src="CoinsBag" class="h-[25px]" />
          </div>
          <!-- <div class="flex items-center gap-1 bg-white rounded-4 px-3 py-1">
            <span class="font-semibold text-sm">
              ציון: {{ Math.round(enrollment?.total_progress) }}
            </span>
            <img :src="GraduationHat" class="h-[25px]" />
          </div> -->
        </div>
      </div>
      <div class="relative min-h-[130px] min-w-[160px] mr-20">
        <GirlReadingBooks class="absolute -bottom-7 left-5 hidden" />
      </div>
    </div>
    <div class="flex flex-col flex-1 gap-3">
      <div
        class="flex items-center font-simplerRegular text-secondary-900 text-base">
        כל השיעורים
        <span class="mr-1 font-simplerRegular text-[#9BAABF] text-base">
          ({{ currentEnrollmentFormat.lessons?.length ?? 0 }})
        </span>
      </div>
      <ScrollableContainer hidescrollbar>
        <div class="flex flex-col flex-1 h-full gap-2">
          <LessonInfo
            v-for="(item, index) in currentEnrollmentFormat.lessons"
            :key="item.id"
            :lesson="item"
            :lesson-coins="lessonCoins[index]"
            :team="lessonsTeam[item.id]"/>
        </div>
      </ScrollableContainer>
    </div>
  </div>

  <!-- <SideWidget>
    <div class="bg-white w-full py-10 px-5 border-2 text-center">
      <RouterLink
        :to="{
          name: 'course-progress',
          params: { course: $route.params.course },
        }">
        <span class="uppercase test-lg">Switch to Old Dashboard</span>
      </RouterLink>
    </div>
  </SideWidget> -->

  <SideWidget>
    <NotificationWidget />
  </SideWidget>

  <SideWidget>
    <ProjectWidget v-if="projectEnrollment" :enrollment="projectEnrollment" />
  </SideWidget>
</template>

<script setup lang="ts">
import GirlReadingBooks from '../assets/girlReadingBooks.svg?component';
// import GraduationHat from './../assets/graduationHat.svg?url';
import CoinsBag from '../assets/coinsBag.svg?url';
import LessonInfo from '/@/components/LessonInfo.vue';
import { computed, onMounted, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useAccountStore } from '/@/store/account';
import { useCourseStore } from '/@/store/course';
import { formatLearningHistoryDetails } from '/@/services/accounts';
import { fetchProjectEnrollments } from '/@/services/projects';
import NotificationWidget from '/@/components/NotificationWidget.vue';
import fetchSkills from '/@/services/content';
// import LessonStatus from '/@/components/LessonStatus.vue';
import { get, map, sumBy } from 'lodash';
// import { useI18n } from 'vue-i18n';
import pluralize from 'pluralize';
import ProjectWidget from '/@/components/ProjectWidget.vue';
import PVButton from 'primevue/button';
import { watchEffect } from 'vue';
import { IEnrollment } from '/@/types/interfaces';
// import ScrollableContainer from '/@/components/ScrollableContainer.vue';
import { SideWidget } from '@amit/layout';
import { ProgressInfo } from '@amit/components';

// const { t } = useI18n();
const accountStore = useAccountStore();
const courseStore = useCourseStore();
const router = useRouter();
const route = useRoute();
const selectedPersonas = ref({ assistant: {} as any, mentor: {} as any });
const dataPersonas = ref();
const projectEnrollment = ref();
const skilyId = '63a1001b6376e0feb759b966';
const currentCourse = computed(() => route.params.course as string);

const currentEnrollment = computed(
  () => accountStore.getCurrentEnrollment(currentCourse.value) as IEnrollment,
);
const currentEnrollmentFormat: any = computed(() =>
  formatLearningHistoryDetails(currentEnrollment.value),
);
const currentLesson = computed(() =>
  accountStore.getCurrentLesson(currentEnrollment.value),
);

const projectId = computed(() => {
  const courseProject = currentEnrollment.value?.course?.assessments?.find(
    assessment => assessment.type === 'project',
  );
  return courseProject?.reference || '';
});

const group = computed(
  () =>
    accountStore.groups?.find(
      group => group.id === currentEnrollment.value?.group,
    ) as any,
);

const enrollmentState = computed(() => {
  if (
    currentEnrollment.value?.course?.poll &&
    currentEnrollment.value?.status == 'pending'
  ) {
    return {
      title: 'יוצאים לדרך',
      action: 'בואו נתחיל בשיעור >',
      route: {
        name: 'onboarding', // should be generic "surveys"
        params: currentEnrollment.value.course.poll,
      },
    };
  }
  const route =
    courseStore?.course?.theme?.landingPage?.image ||
    currentCourse.value === skilyId
      ? {
          name: 'course-home',
          params: { course: currentCourse.value },
        }
      : {
          name: 'skill-lesson',
          params: {
            lesson: currentLesson.value,
          },
        };

  return {
    title: 'ממשיכים בתנועה!',
    action: 'בואו נמשיך בשיעור >',
    route,
  };
});

// const disciplines = computed(() =>
//   courseStore.course.disciplines
//     ? courseStore.course.disciplines.join(', ')
//     : '',
// );

const lessonCoins = computed(() => {
  return map(courseStore.course.outline, lesson => {
    return sumBy(lesson.sections, 'coins');
  });
});

const courseModule = computed(() => {
  return accountStore.getCourseModule(currentCourse.value);
});

const team = computed(() => {
  const teams = courseModule.value?.teams ?? [];
  return (
    teams.find(team => {
      return team.students.some(student => student.id === accountStore.user.id);
    })?.students ?? []
  );
});

const lessonsTeam = computed(() => {
  const lessonsSummary = courseModule.value?.summary ?? [];

  return lessonsSummary.reduce((summary, { lesson, teams_enabled }) => {
    summary[lesson] = teams_enabled ? team.value : [];
    return summary;
  }, {});
});

// const showPersonas = computed(() => {
//   return (
//     courseStore.course.caption === 'ניהול עצמי' &&
//     selectedPersonas.value.assistant?.id
//   );
// });

const getPersonaData = type => {
  const personaId = get(accountStore, `enrollments[0][${type}]`, '');
  return dataPersonas.value[pluralize(type)].find(
    item => item.id === personaId,
  );
};

onMounted(() => {
  courseStore.fetchCourse(currentCourse.value);
  accountStore.setEnrollments(currentCourse.value);
  accountStore.getUser(true);
  const { onSuccess } = fetchSkills();
  onSuccess(({ data }) => {
    dataPersonas.value = data[0];
    selectedPersonas.value['mentor'] = getPersonaData('mentor');
    selectedPersonas.value['assistant'] = getPersonaData('assistant');
  });
});

watchEffect(() => {
  if (!projectId.value) {
    return;
  }
  const { onSuccess } = fetchProjectEnrollments(
    projectId.value,
    accountStore.user.id,
  );
  onSuccess(({ data }) => {
    if (data?.length) {
      projectEnrollment.value = data[0];
    }
  });
});

watchEffect(() => {
  const group = currentEnrollment.value?.group;
  if (!group) {
    return;
  }

  accountStore.setCourseModules(group, currentCourse.value);
});

const lessonsProgress = computed(() =>
  currentEnrollmentFormat.value.lessons?.map(lesson => lesson.progress),
);
</script>

<style>
.course-description {
  p {
    @apply font-simplerRegular text-sm text-secondary-800 !important;
  }

  p a {
    @apply inline;
  }
}
</style>
